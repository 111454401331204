<template>
  <v-col>
    <v-container>
      <div v-if="!installerCarId">
        <DialogCard :showDialog.sync="newCustDialog">
          <template v-slot:title>{{ $t("new_customer") }}</template>
          <template v-slot:text>
            <v-form>
              <v-row>
                <v-col>
                  <v-text-field
                    :label="$t('phone')"
                    v-model="newCustomer.phone"
                  ></v-text-field>
                  <v-text-field
                    :label="$t('customer_name')"
                    v-model="newCustomer.name"
                  />

                  <v-select
                    v-show="!isInstaller"
                    :items="shops"
                    item-text="name"
                    item-value="_id"
                    v-model="newCustomer.shopId"
                  ></v-select>
                  <label v-if="newCustomer.qr">QR: {{ newCustomer.qr }}</label>
                </v-col>
              </v-row>
            </v-form>
          </template>
          <template v-slot:confirm>
            <v-btn class="btn-confirm" @click="SaveCustomer">{{
              $t("save")
            }}</v-btn>
          </template>
        </DialogCard>

        <DialogCard v-if="addQrDialog" :showDialog.sync="addQrDialog">
          <template v-slot:title> {{ $t("menu_add_qr") }}</template>
          <template v-slot:text>
            <label>Scan Qr code to add.</label>
            <QR @QrReceived="AddQr"></QR>
            <v-overlay :value="overlayLoading">
              <v-progress-circular
                indeterminate
                size="64"
              ></v-progress-circular>
            </v-overlay>
          </template>
        </DialogCard>

        <DialogCard :showDialog.sync="carDialog" min-width="30vw">
          <template v-slot:title>{{ $t("new_car_registration") }}</template>
          <template v-slot:text>
            <v-form style="width: 300px; margin: auto">
              <v-text-field outlined :label="$t('plate')" v-model="car.plate" />
              <v-text-field outlined :label="$t('make')" v-model="car.make" />
              <v-text-field outlined :label="$t('model')" v-model="car.model" />
              <v-select
                outlined
                v-if="carTypes"
                :label="$t('car_body')"
                :items="carTypes"
                item-value="_id"
                v-model="car.type"
              >
                <template v-slot:item="{ item }">
                  {{
                    (item.text = $t(
                      "carTypes." + item.type.replaceAll(" ", "_")
                    ))
                  }}
                </template>
              </v-select>
              <v-text-field outlined :label="$t('year')" v-model="car.year" />
            </v-form>
          </template>
          <template v-slot:actions>
            <v-btn class="btn-confirm" @click="SaveCar(customer._id)">{{
              $t("save")
            }}</v-btn>
            <v-btn @click="CancelCarSave">{{ $t("cancel") }}</v-btn>
          </template>
        </DialogCard>
        <DialogCard width="350px" :showDialog.sync="promptForQr">
          <template v-slot:title>{{ $t("qr_add") }}</template>
          <template v-slot:text
            ><div class="text-center">{{ $t("qr_prompt") }}</div></template
          >
          <template v-slot:actions>
            <v-btn
              class="btn-confirm"
              @click="
                () => {
                  addQrDialog = true;
                  promptForQr = false;
                }
              "
              >{{ $t("ok") }}</v-btn
            >
            <v-btn @click="promptForQr = false">{{ $t("cancel") }}</v-btn>
          </template>
        </DialogCard>
        <v-container class="text-center justify-center">
          <v-btn-toggle v-model="buttonToggle" class="pb-5">
            <v-btn
              v-model="showSearchBar"
              @click="
                () => {
                  showQr = false;
                  showSearchBar = true;
                }
              "
              >{{ $t("btn_text") }}</v-btn
            >
            <v-btn
              icon
              v-model="showQr"
              @click="
                () => {
                  showQr = true;
                  showSearchBar = false;
                }
              "
              ><v-icon>mdi-qrcode-scan</v-icon>
            </v-btn>
          </v-btn-toggle>

          <v-form
            v-if="showSearchBar"
            :loading="btnLoading"
            v-on:submit.prevent="CheckNumber()"
          >
            <v-row>
              <v-text-field
                :label="$t('phone_or_plate')"
                v-model="phone"
              ></v-text-field>
              <v-btn icon style="align-self: center" type="submit"
                ><v-icon>mdi-magnify</v-icon></v-btn
              >
            </v-row>
          </v-form>
        </v-container>
        <v-form v-if="showQr">
          <QR @QrReceived="QrReceived"></QR>
        </v-form>

        <v-card
          v-if="showSearchResult && customer"
         
          class="justify-center; align-center"
        >
          <v-card-title class="justify-center">
            {{ customer.name }}
          </v-card-title>
          <v-card-text class="text-center">
            <v-row>
              <v-col>
                <label>{{ $t("phone") }}:</label> <b>{{ customer.phone }}</b>
              </v-col>
            </v-row>
            <v-row v-if="customer.cars" justify="space-between">
              <v-col xs="4" v-for="(car, index) in customer.cars" :key="index">
                <v-card @click="SelectCar(car._id)"
				style="border: 1px solid aliceblue;">
                  <v-card-title class="justify-center">
                    {{ car.make }}
                  </v-card-title>
                  <v-card-text>
                    <v-divider></v-divider>
                    <p>{{ $t("plate") }}: {{ car.plate }}</p>
                    <div v-if="$store.getters.getUserRole === 'dev'">
                      <span>_id: {{ car._id }}</span>
                      <button
                        class="btn v-btn--outlined"
                        @click.stop="DeleteCar(customer._id, car._id)"
                      >
                        Delete
                      </button>
                      <!-- <p>ServiceWork: {{GetServiceWorkCount(customer._id)}}</p> -->
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <v-card-actions>
              <v-menu offset-x>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    class="mx-2"
                    fab
                    dark
                    small
                    color="indigo"
                  >
                    <v-icon dark>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="carDialog = true">
                    <v-list-item-title>{{
                      $t("menu_add_car")
                    }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="addQrDialog = true">
                    <v-list-item-title>{{
                      $t("menu_add_qr")
                    }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-card-actions>
          </v-card-text>
        </v-card>
        <v-card v-if="showSearchResult && !customer">
          <v-card-title class="justify-center">
            {{ $t("text_customer_not_found") }} {{ phone }}
          </v-card-title>
          <v-card-text>
            <v-btn @click="ShowNewUserDialog">{{ $t("add_customer") }}</v-btn>
          </v-card-text>
        </v-card>
        <v-card
          v-if="!showSearchResult && !customer"
          elevation="0"
          style="height: 25vh"
        >
          <v-card-title></v-card-title>
          <!-- <v-card-text class="text-center">{{ $t("search") }}</v-card-text> -->
        </v-card>
      </div>
      <div v-if="showServiceWork">
        <ServiceWorkV5
          :serviceWorkProps="installerCarId"
          @back="InitializeLayout"
        ></ServiceWorkV5>
      </div>
    </v-container>
  </v-col>
</template>

<script>
import axios from 'axios'
import { bus } from '../main'
import QR from './QR'
import ServiceWorkV5 from './ServiceWork/ServiceWorkV5.vue' // "./ServiceWork/ServiceWorkV5";
import DialogCard from './DialogCard'
import _ from 'lodash'
export default {
	name: 'ServiceInstaller',
	components: {
		ServiceWorkV5,
		QR,
		DialogCard,
	},
	data() {
		return {
			promptForQr: false,
			btnLoading: false,
			overlayLoading: false,
			test: '',
			phone: '',
			customer: '',
			newCustomer: '',
			fountCustomer: '',
			shops: [],
			car: {},
			showSearchResult: null,
			newCustDialog: null,
			carDialog: null,
			showInstallerDropdown: false,
			allowedUsers: ['dev', 'admin', 'supervisor'],
			carTypes: [],
			localizedCarTypes: [],
			isInstaller: Boolean,
			selectedCar: '',
			installerCarId: '',
			showSearchBar: true,
			showQr: false,
			qr: '',
			addQrDialog: false,
			showServiceWork: false,
			buttonToggle: 0,
			addMenu: false,
		}
	},
	created() {
		this.InitializeData()
		this.GetCarBody()
		this.GetShopId()
		this.GetShops()
		this.GetIsInstaller()
	},
	watch: {
		phone: function () {
			this.showSearchResult = false
		},
		showSearchBar( value ) {
			if ( value ) {
				this.addQrDialog = false
				this.showQr = false
			}
		},
		addQrDialog( value ) {
			if ( value ) {
				this.showSearchBar = false
				this.showQr = false
			}
		},
		showQr( value ) {
			if ( value ) {
				this.showSearchBar = false
				this.addQrDialog = false
			}
		},
	},
	computed: {
		// async isInstaller() {await this.$store.dispatch('checkRole',['installer'])}
	},
	methods: {
		GetLocalizedBodyName( type ) {
			console.log( type )
			return 'carTypes.' + type.replaceAll( ' ', '_' )
		},
		InitializeLayout() {
			this.InitializeData()
			// this.showSearchResult = null;
			// this.newCustomer
			this.showSearchBar = true
			this.buttonToggle = 0
			this.newCustDialog = false
			this.carDialog = false
			this.showSearchResult = false
			this.addQrDialog = false
			this.showServiceWork = false
		},
		InitializeData() {
			console.log( 'initializeData called' )
			this.phone = ''
			this.car = {
				plate: '',
				make: '',
				model: '',
				type: '',
				year: '',
			}
			this.newCustomer = {
				userId: '',
				phone: '',
				name: '',
				shopId: '',
				qr: '',
			}
			this.installerCarId = null
		},
		async QrReceived( data ) {
			await this.CheckQrCode( data )
			this.showSearchBar = true
			this.showSearchResult = true
		},
		async AddQr( qr ) {
			try {
				this.overlayLoading = true
				let qrResponse = await axios.patch(
					process.env.VUE_APP_BASEURL + 'customer/qr/add/' + this.customer._id,
					{ qr: qr }
				)
				let data = qrResponse.data
				if ( data ) {
					bus.$emit( 'snack-success', 'Added new Qr to user.' )
				} else {
					bus.$emit( 'snack-error', qrResponse.data )
				}
				this.overlayLoading = false
				this.addQrDialog = false
			} catch ( e ) {
				bus.$emit( 'snack-error', 'error adding Qr' )
				this.addQrDialog = false
				this.overlayLoading = false
				console.log( 'Error adding Qr' )
				console.log( '=================' )
				console.log( e )
				console.log( '=================' )
			}
		},
		async GetCarBody() {
			try {
				this.overlayLoading = true
				let carBodyResponse = await axios.get(
					process.env.VUE_APP_BASEURL + 'carBody/'
				)

				this.carTypes = carBodyResponse.data

				//get localized types
				this.localizedCarTypes = JSON.parse( JSON.stringify( this.carTypes ) )
				for ( let i = 0; i < this.localizedCarTypes.length; i++ ) {
					this.localizedCarTypes[i].type = this.$t(
						this.localizedCarTypes[i].type.replaceAll( ' ', '_' )
					)
				}

				// console.log(carBodyResponse.data);
				this.overlayLoading = false
			} catch ( e ) {
				this.overlayLoading = false
				bus.$emit( 'snack-error', 'Error getting body parts.' )
				console.log( e )
			}
		},
		async CheckQrCode( qrString ) {
			try {
				this.overlayLoading = true
				const qrResponse = await axios.get(
					process.env.VUE_APP_BASEURL + 'customer/qr/' + qrString
				)
				if ( !_.isNil( qrResponse ) && qrResponse.statusText === 'OK' ) {
					let responseData = qrResponse.data
					if ( !_.isNil( responseData ) && !_.isString( responseData ) ) {
						console.log( responseData )
						this.overlayLoading = false
						this.showQr = false
						this.showSearchBar = true
						this.customer = qrResponse.data
						this.qr = qrString
						this.phone = this.customer.phone
					} else {
						this.overlayLoading = false
						bus.$emit( 'snack-error', responseData )
					}
				} else {
					this.overlayLoading = false
					bus.$emit( 'snack-error', 'User Not Found' )
				}
			} catch ( e ) {
				this.overlayLoading = false
				bus.$emit( 'snack-error', 'Error getting info: ' + e.message )
				console.error( e )
			}
		},
		async CheckNumber() {
			try {
				//only try to submit search if number is not null and is a number.
				if ( !_.isNil( this.phone ) && this.phone !== '' && !isNaN( this.phone ) ) {
					this.btnLoading = true
					this.overlayLoading = true
					const result = await axios.get(
						process.env.VUE_APP_BASEURL + 'customer/search/' + this.phone
					)
					if ( result.data ) {
						this.customer = result.data
					} else {
						this.customer = ''
					}
					this.overlayLoading = false
					this.showSearchResult = true
				} else {
					bus.$emit(
						'snack-error',
						'Please enter a number in the search field.'
					)
				}
			} catch ( exception ) {
				this.overlayLoading = false
				console.log( exception )
			}
			this.btnLoading = false
		},
		async GetIsInstaller() {
			let installer = await this.$store
				.dispatch( 'checkRole', ['installer'] )
				.then( ( result ) => {
					return result
				} )
			this.isInstaller = installer
		},
		ShowNewUserDialog() {
			if (
				this.phone &&
        this.phone.length !== 8 &&
        !Number.isInteger( this.phone )
			) {
				this.phone = ''
			}
			this.newCustomer.phone = this.phone
			let userId = this.$store.getters.getUserId
			this.newCustomer.shopId = userId
			this.newCustomer.qr = this.qr
			this.newCustDialog = true
		},
		async GetShopId() {
			let user = this.$store.getters.getUserId
		},
		async GetShops() {
			let allowed = await this.$store.dispatch( 'checkRole', this.allowedUsers )
			if ( allowed ) {
				let shopResponse = await axios.get(
					process.env.VUE_APP_BASEURL + 'user/installers'
				)
				this.shops = shopResponse.data
			}
		},
		async SaveCustomer() {
			if ( this.ValidateCustomer() ) {
				try {
					let response = await axios.post(
						process.env.VUE_APP_BASEURL + 'customer/',
						this.newCustomer
					)
					if ( response ) {
						bus.$emit( 'snack-success', 'Customer Added Successfully.' )
						this.customer = response.data
						this.newCustDialog = false
					} else {
						bus.$emit( 'snack-error', 'Customer failed to add.' )
					}
				} catch ( exception ) {
					console.log( 'error: ' + exception )
					bus.$emit( 'snack-error', 'Error adding customer.' )
				}
			}
		},
		async SaveCar( customerId ) {
			if ( this.ValidateCar( customerId ) ) {
				try {
					const response = await axios.post(
						process.env.VUE_APP_BASEURL + 'customer/addcar/' + customerId,
						this.car
					)
					this.car = response.data
					this.customer.cars.push( this.car )
					this.carDialog = false
					this.promptForQr = true
				} catch ( exception ) {
					bus.$emit( 'snack-error', 'Error adding car.' )
					console.log( exception )
				}
			}
		},
		async DeleteCar( customerId, carId ) {
			try {
				const response = await axios.post(
					process.env.VUE_APP_BASEURL + 'customer/removecar/' + customerId,
					carId
				)
				if (
					response.statusText === 'OK' &&
          response !== null &&
          response !== undefined
				) {
					this.customer.cars = this.customer.cars.filter(
						( car ) => car._id !== carId
					)
				}
			} catch ( exception ) {
				bus.$emit( 'snack-error', 'Error removing car.' )
				console.log( exception )
			}
		},
		ValidateCar( customerId ) {
			if ( !customerId ) {
				bus.$emit( 'snack-error', 'Customer Id not found.' )
				return false
			}
			if ( !this.car.plate ) {
				bus.$emit( 'snack-error', 'Please enter car plate.' )
				return false
			}
			if ( !this.car.make ) {
				bus.$emit( 'snack-error', 'Please Enter car make.' )
				return false
			}
			if ( !this.car.type ) {
				bus.$emit( 'snack-error', 'Please enter car type.' )
				return false
			}

			return true
		},
		CancelCarSave() {
			this.car = {}
			this.carDialog = false
		},
		ValidateCustomer() {
			if ( !this.newCustomer.name ) {
				bus.$emit( 'snack-error', 'Please enter customer name' )
				return false
			}
			if ( !this.newCustomer.phone ) {
				bus.$emit( 'snack-error', 'Please enter phone' )
				return false
			}
			if ( this.newCustomer.phone.length !== 8 ) {
				bus.$emit( 'snack-error', 'Phone must be 8 digits.' )
				return false
			}
			if ( !this.newCustomer.shopId ) {
				bus.$emit( 'snack-error', 'ShopId not found.' )
				return false
			}
			return true
		},
		SelectCar( carId ) {
			this.installerCarId = {
				carId: carId,
				installerId: this.$store.getters.getUserId,
				customerId: this.customer._id,
			}
			this.showServiceWork = true
		},
	},
}
</script>
