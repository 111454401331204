<template>
  <v-container
    v-if="stock[0] !== undefined"
    style="border: 1px solid dimgray; padding-bottom: 82px"
  >
    <v-btn icon outlined color="red" @click="$emit('remove', product)"
      ><v-icon>mdi-close</v-icon></v-btn
    >
    <!--       <table>-->
    <!--           <thead>-->
    <!--           <th>Selected Parts</th>-->
    <!--           <th>Previously Selected</th>-->
    <!--           </thead>-->
    <!--           <tbody>-->
    <!--           <td>{{selectedParts}}</td>-->
    <!--           <td>{{previouslySelected}}</td>-->
    <!--           </tbody>-->
    <!--       </table>-->
    <h2 class="text-center pa-4">{{ stock[0].productId.name }}</h2>
    <!-- Body parts being passed{{ bodyParts }} -->
    <CarBody
      v-if="bodyParts"
      :Body="bodyParts"
      :SafetySelected="this.safetySelected"
      :blockWindshield="this.blockWindshield"
      :category="stock[0].productId.category"
      :previouslySelected="previouslySelected"
      :selected-package="selectedPackage"
      :select-parts="selectParts"
      @onSelectionChange="UpdateSelectedParts"
    />
  </v-container>
</template>

<script>
import CarBody from '../CarBody'
export default {
	name: 'CarProductSelection',
	components: { CarBody },
	props: [
		'product',
		'stock',
		'bodyParts',
		'previouslySelected',
		'safetySelected',
		'blockWindshield',
		'selectParts',
    'selectedPackage',
	],
	data() {
		return {
			selectedProduct: '',
			selectedParts: [],
		}
	},
	methods: {
		UpdateSelectedParts(newParts) {
			this.selectedParts = newParts
			this.$emit('partsUpdated', this.selectedParts)
		},
	},
}
</script>
