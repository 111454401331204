import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import VueI18n from 'vue-i18n'
import arjson from '../locales/ar.json'
import enjson from '../locales/en.json'
import en from 'vuetify/es5/locale/en'
import ar from 'vuetify/es5/locale/ar'
const messages = {
	en: {
		// ...enjson,
		$vuetify: {
			...en,
		},
	},
	ar: {
		// ...arjson,
		$vuetify: {
			...ar,
		},
	},
}
Vue.use( Vuetify )
Vue.use( VueI18n )
const i18n = new VueI18n( {
	locale: 'en', // set locale
	messages, // set locale messages
} )

// export default new Vuetify({
//     lang: {
//         t: (key, ...params) => i18n.t(key, params),
//     },
export default new Vuetify( {
	lang: {
		locales: { en, ar },
		// current:'en',
		t: ( key, ...params ) => i18n.t( key, params ),
	},
	theme: {dark: true}
} )
