import Vue from 'vue'
import VueRouter from 'vue-router'
import AdminDash from '../views/AdminDash'
import InstallerDash from '../views/InstallerDash'
import LandingPage from '../views/LandingPage'
import axios from 'axios'
import { _ } from 'core-js'

Vue.use( VueRouter )

const createRoutes =( store )=> [
	{ 
		path: '',
		component: () => import( '../layouts/MainLayout' ),
		meta: {
		},
		children: [
			{
				path     : '/qr/:id',
				props    : true,
				name     : 'CustomerRouting',
				component: () => import( '../views/CustomerRouting' )
			},
			
			
			{
				path     : '/',
				name     : 'Dashboard',
				meta: {
					requiresAuth: true,
				},
				beforeEnter: ( to, from, next ) => {
					console.log( 'dashboard before enter' )
					// console.log( 'store getter before enter landing page', store.getters.isLoggedIn )
					if( !store.getters.getUserRole ){
						next( '/login' )
					}
					else{
						next()
					}
				},
				component: {
			  // const role = store.getters.getUserRole.toLowerCase()
					render: ( h ) => {
						console.log( 'dashboard render', store.getters.getUserRole )
						switch ( store.getters.getUserRole.toLowerCase() ) {
						case 'admin'     : 
						case 'dev'       : 
						case 'supervisor': 
							return h( AdminDash )
						case 'installer': 
							return h( InstallerDash )
						default: 
							console.log( 'no role found' )
							return
						}
					},
				},
			},
			{
				path: '/adminDash',
				name: 'AdminDash',
				meta: {
					requiresAuth: true,
					roles       : ['admin', 'supervisor'],
				},
				component: () => import( '../views/AdminDash' ),
			},
			{
				path: '/installerDash',
				name: 'InstallerDash',
				meta: {
					requiresAuth: true,
					roles       : ['installer'],
				},
				component: () => import( '../views/InstallerDash' ),
			},
			{
				path: '/productView',
				name: 'ProductView',
				meta: {
					requiresAuth: true,
					roles       : ['admin', 'supervisor', 'dev'],
				},
				component: () => import( '../views/ProductView' ),
			},
			{
				path: '/customerExplorer',
				name: 'CustomerExplorer',
				meta: {
					requiresAuth: true,
					roles       : ['admin', 'supervisor', 'dev'],
				},
				component: () => import( '../views/CustomerExplorer' ),
			},
			{
				path: '/stockView',
				name: 'StockView',
				meta: {
					requiresAuth: true,
					roles       : ['admin', 'dev', 'supervisor'],
				},
				component: () => import( '../views/StockView' ),
			},
			{
				path: '/ServiceInstallerView',
				name: 'ServiceInstallerView',
				meta: {
					requiresAuth: true,
					roles       : ['admin', 'supervisor', 'installer'],
				},
				component: () => import( '../components/ServiceInstaller' ),
			},
			{
				path: '/userView',
				name: 'UserView',
				meta: {
					requiresAuth: true,
					roles       : ['admin', 'supervisor'],
				},
				component: () => import( '../views/UserView' ),
			},
			{
				path     : '/WorkHistoryView',
				name     : 'WorkHistoryView',
				component: () => import( '../views/WorkHistoryView' ),
			},
			{
				path     : '/WorkHistoryView/:customerId',
				name     : 'WorkHistoryView',
				props    : true,
				component: () => import( '../views/WorkHistoryView' ),
			},
			{
				path     : '/CarPartAdder',
				name     : 'CarPartAdder',
				component: () => import( '../utility/CarPartAdder' ),
			},
			{
				path     : '/ServiceWorkPage',
				name     : 'ServiceWorkPage',
				component: () => import( '../components/ServiceWorkPage' ),
			},
			{
				path     : '/DevPage',
				name     : 'DevPage',
				component: () => import( '../utility/DevPage' ),
			},
	
		]
	},
	{
		path: '/login',
		component: () => import( '../layouts/GuestLayout' ),
		children:[
			{
				path       : '',
				name       : 'LandingPage',
				component  : LandingPage,
				beforeEnter: ( to, from, next ) => {
					console.log( 'store getter before enter landing page', store.getters.isLoggedIn )
					if ( store.getters.isLoggedIn ) {
						next( '/' )
					}
					else{
						next()
					}
				},
			},
		]
	},
	//path for product page with batch number
	{
		path: '/batch/:batch_number',
		name: 'batch',
		async beforeEnter( to, from, next ) {
			store.state.showSplash = true
			const { batch_number } = to.params
			let current_url = 'https://www.thenanogard.com/products/'
	  //if an id is passed, 
			if ( !_.isNil( batch_number ) ) {
		  //search for stock with that batch number and get the url.
				const batch_response = await axios.get( `${process.env.VUE_APP_BASEURL}batch/${batch_number}` )
				if ( batch_response ) {
					console.log( batch_response )
					const { url } = batch_response.data
					if( batch_response.data.installerId ){
						//TODO: if the batch has an installer id, then we need to redirect to the ProductPage page along with the batch as a param and installerId as a prop.
						console.log( 'batch has installer id' )
						next( { path: '/ProductPage/'+batch_number } )
						//short circuit the rest of the function so we don't redirect twice.
						store.state.showSplash = false
						return
					}
					if ( url ) {
						current_url = url
					}
					else {
						console.log( 'no url found' )
				
					}
				}

			}
			else{

				console.log( 'Batch number not included.' )
			}
			store.state.showSplash = false
			window.location.href = current_url

		}

	},
	{
		path     : '/ProductPage/:batch_number',
		name     : 'ProductPage',
		props    : true,
		component: () => import( '../views/ProductPage' ),
		
	},
	{
		path: '/maintenance',
		component: () => import( '../layouts/NoFrillsLayout' ),
		children:[
			{
				path     : '',
				name     : 'Maintenance',
				component: () => import( '../views/MaintenanceScreen' ),
			}
		]
	},
	{
		path: '/:catchAll(.*)',
		name: 'PageNotFound',
		component: () => import( '../views/PageNotFound' ),
	}
	// { 
	// 	path: '/login',
	// 	name: 'Login',
	// 	component: () => import( '../views/Login' ),
	// },
	// {
	// 	path     : '/maintenance',
	// 	name     : 'Maintenance',
	// 	component: () => import( '../views/MaintenanceScreen' ),
	// }
]


const createRouter=( store )=>{
	const routes = createRoutes( store )

	const router = new VueRouter( {
	
		routes
	} )
	// checkes if the page you are entering needs authorization and kicks you out if you aren't authorized.
	router.beforeEach( ( to, from, next ) => {
		const urole = store.getters.getUserRole?.toLowerCase()
	  //check if we are rerouting navigation, do nothing if we are.
		if ( to.path === from.path && to.name === from.name ) {
			console.log( 'Path duplicated' )
			return
		}
	  //check if the path requires authentication and that the current user is authenticated.
		// console.log( 'to.matched.some( record => record.meta.requiresAuth )', to )	
		console.log( 'to.matched', to.matched )
		console.log( 'to.matched.some( record => record.meta.requiresAuth )', to.matched.some( record => record.meta.requiresAuth ) )
	  if ( to.matched.some( record => record.meta.requiresAuth ) ) {
		// If roles are defined, check if the user has the role.
			if ( to.matched.some( record => record.meta.roles ) ) {
				const requiresRoles = to.matched.filter( record => record.meta.roles && record.meta.roles.length > 0 )
				if ( urole && requiresRoles.some( record =>  record.meta.roles.some( ( role ) => role === urole ) )
			|| urole === 'dev' ) {
					next()
					return
				}
				else {
					console.log( 'not authenticated1' )
					next( { path: '/login' } )
					return
				}
			}
			// console.log( 'check if user is logged in', store.getters.isLoggedIn )
			// console.log( 'check if user data exists', store.state )
			// If no roles are defined, check if the user is authenticated.
			if ( store.getters.isLoggedIn ) {
				next()
				return
			}
			console.log( 'not authenticated2' )
		  //if you are not authenticated, route back to root.
			next( { path: '/login' } )
			return
		}
	  // if not authenticated continue.		
		next()
	}
	)
	return router
}
// const router = new VueRouter( {
// 	  // mode: 'history',
// 	routes,
// } )



export default createRouter
