import Vue from 'vue'
import axios from 'axios'
import { bus } from '../main'
import _ from 'lodash'

export  class ServiceUtility {
	constructor(){
		this.packages = [
			{
				name: 'full_body',
				parts: [
					{ partCategory: 'Panel', partName: 'Front Bumper' },
					{ partCategory: 'Panel', partName: 'Hood' },
					{ partCategory: 'Panel', partName: 'Left Fog Light' },
					{ partCategory: 'Panel', partName: 'Left Front Door' },
					{ partCategory: 'Panel', partName: 'Left Front Fender' },
					{ partCategory: 'Panel', partName: 'Left Rear Door' },
					{ partCategory: 'Panel', partName: 'Left Rear Fender' },
					{ partCategory: 'Panel', partName: 'Left Side Mirror' },
					{ partCategory: 'Panel', partName: 'Lower Grille' },
					{ partCategory: 'Panel', partName: 'Rear Bumper' },
					{ partCategory: 'Panel', partName: 'Rear Door' },
					{ partCategory: 'Panel', partName: 'Right Fog Light' },
					{ partCategory: 'Panel', partName: 'Right Front Door' },
					{ partCategory: 'Panel', partName: 'Right Front Fender' },
					{ partCategory: 'Panel', partName: 'Right Rear Door' },
					{ partCategory: 'Panel', partName: 'Right Rear Fender' },
					{ partCategory: 'Panel', partName: 'Right Side Mirror' },
					{ partCategory: 'Panel', partName: 'Roof' },
					{ partCategory: 'Light', partName: 'Left Fog Light' },
					{ partCategory: 'Light', partName: 'Left Head Light' },
					{ partCategory: 'Light', partName: 'Left Rear Light' },
					{ partCategory: 'Light', partName: 'Right Fog Light' },
					{ partCategory: 'Light', partName: 'Right Head Light' },
					{ partCategory: 'Light', partName: 'Right Rear Light' },
					// {partCategory:'Panel', partName:''},
				],
			},
			{
				name: 'full_frontal',
				parts: [
					{ partCategory: 'Panel', partName: 'Front Bumper' },
					{ partCategory: 'Panel', partName: 'Hood' },
					{ partCategory: 'Panel', partName: 'Left Fog Light' },
					{ partCategory: 'Panel', partName: 'Left Front Door', edge: true },
					{ partCategory: 'Panel', partName: 'Left Front Fender' },
					{ partCategory: 'Panel', partName: 'Left Rear Door', edge: true },
					{ partCategory: 'Panel', partName: 'Left Rear Fender', partial: true },
					{ partCategory: 'Panel', partName: 'Left Side Mirror' },
					{ partCategory: 'Panel', partName: 'Lower Grille' },
					{ partCategory: 'Panel', partName: 'Right Fog Light' },
					{ partCategory: 'Panel', partName: 'Right Front Door', edge: true },
					{ partCategory: 'Panel', partName: 'Right Rear Door', edge: true },
					{ partCategory: 'Panel', partName: 'Right Front Fender' },
					{ partCategory: 'Panel', partName: 'Right Rear Fender', partial: true },
					{ partCategory: 'Panel', partName: 'Right Side Mirror' },
					{ partCategory: 'Panel', partName: 'Roof', partial: true },
					{ partCategory: 'Light', partName: 'Left Fog Light' },
					{ partCategory: 'Light', partName: 'Left Head Light' },
					{ partCategory: 'Light', partName: 'Right Fog Light' },
					{ partCategory: 'Light', partName: 'Right Head Light' },
					// {partCategory:'Panel', partName:''},
				],
			},
			{
				name: 'half_quarter_hood',
				parts: [
					{ partCategory: 'Panel', partName: 'Front Bumper' },
					{ partCategory: 'Panel', partName: 'Hood', partial: true },
					{ partCategory: 'Panel', partName: 'Left Fog Light' },
					{ partCategory: 'Panel', partName: 'Left Front Door', edge: true },
					{ partCategory: 'Panel', partName: 'Left Front Fender', partial: true },
					{ partCategory: 'Panel', partName: 'Left Rear Door', edge: true },
					{ partCategory: 'Panel', partName: 'Left Rear Fender', partial: true },
					{ partCategory: 'Panel', partName: 'Left Side Mirror' },
					{ partCategory: 'Panel', partName: 'Lower Grille' },
					{ partCategory: 'Panel', partName: 'Right Fog Light' },
					{ partCategory: 'Panel', partName: 'Right Front Door', edge: true },
					{ partCategory: 'Panel', partName: 'Right Rear Door', edge: true },
					{
						partCategory: 'Panel',
						partName: 'Right Front Fender',
						partial: true,
					},
					{ partCategory: 'Panel', partName: 'Right Rear Fender', partial: true },
					{ partCategory: 'Panel', partName: 'Right Side Mirror' },
					{ partCategory: 'Panel', partName: 'Roof', partial: true },
					{ partCategory: 'Light', partName: 'Left Fog Light' },
					{ partCategory: 'Light', partName: 'Left Head Light' },
					{ partCategory: 'Light', partName: 'Right Fog Light' },
					{ partCategory: 'Light', partName: 'Right Head Light' },
					// {partCategory:'Panel', partName:''},
				],
			},
			{
				name: 'window_film',
				parts: [
					{ partCategory: 'Glass', partName: 'Left Front Door' },
					{ partCategory: 'Glass', partName: 'Left Rear Door' },
					{ partCategory: 'Glass', partName: 'Left Rear Quarter' },
					{ partCategory: 'Glass', partName: 'Rear Gate' },
					{ partCategory: 'Glass', partName: 'Right Front Door' },
					{ partCategory: 'Glass', partName: 'Right Rear Door' },
					{ partCategory: 'Glass', partName: 'Right Rear Quarter' },
				],
			},
		]

	}
	GetPackageParts( packageName, parts ) {
		console.log( 'packageName: ', packageName )
		console.log( 'parts: ', parts )
		console.log( 'this.packages: ', this.packages )
		
		let foundPackage = this.packages.find( ( p ) => p.name.toLowerCase( ) === packageName.toLowerCase() )
		if ( foundPackage ) {
			let selectedParts = parts.filter( ( part ) =>
				foundPackage.parts.some(
					( ppart ) =>
						ppart.partName === part.partName &&
            ppart.partCategory === part.partCategory
				)
			)

			//test to add partial.
			let newSelectedParts = []
			foundPackage.parts.forEach( ( packagePart ) => {
				let foundPart = parts.find(
					( p ) =>
						p.partName === packagePart.partName &&
            p.partCategory === packagePart.partCategory
				)
				if ( packagePart.partial ) {
					foundPart.partial = true
				}
				if ( packagePart.edge ) {
					foundPart.edge = true
				}
				newSelectedParts.push( foundPart )
			} )
			return newSelectedParts
		}
		return []
	}
	async GetInstallerStock( installerId ) {
		try {
			let stockResponse = await axios.get(
				process.env.VUE_APP_BASEURL + 'stock/installerStock/' + installerId
			)
			return stockResponse.data
		} catch ( e ) {
			bus.$emit( 'snack-error', 'Error Getting Installer Stock.' )
			console.log( e )
			return null
		}
	}

	ToMeter( cm ) {
		return cm / 100
	}
	async GetCarData( carId ) {
		try {
			let carResponse = await axios.get(
				process.env.VUE_APP_BASEURL + 'customer/car/' + carId
			)
			return carResponse.data
		} catch ( e ) {
			bus.$emit( 'snack-error', 'Error Getting Car.' )
			console.log( e )
			return null
		}
	}

	async GetCarBodyParts( carTypeId ) {
		try {
			let carBodyResponse = await axios.get(
				process.env.VUE_APP_BASEURL + 'carBody/' + carTypeId
			)
			return carBodyResponse.data
		} catch ( e ) {
			bus.$emit( 'snack-error', 'Error getting Parts' )
			console.log( e )
			return null
		}
	}

	GetPartsByCategory( allParts, categoryName ) {
		let separatedParts = []
		allParts.forEach( ( part ) => {
			if ( part.productCategory.some( ( p ) => p === categoryName ) ) {
				separatedParts.push( part )
			}
		} )
		return separatedParts
	}

	GetStockByCategory( stocks, categoryName ) {
		if( !_.isNil( stocks ) && !_.isNil( categoryName ) ){

			return stocks.filter( ( s ) => s.productId.category === categoryName )
		}
		return []
	}
	ErasePreviousStockData( carConfig ) {
		if ( carConfig.selectedStock && carConfig.selectedStock.length > 0 ) {
			carConfig.selectedStock.map( ( s ) => {
				s.m2WillUse = 0
			} )
		}
		carConfig.stock.map( ( s ) => ( s.m2WillUse = 0 ) )
	}
	UpdateCarConfigCalculations( carConfig ) {
		let graceM2 = 200
		this.ErasePreviousStockData( carConfig )
		// debugger
		let stock = carConfig.selectedStock
		let totalM2WillUse = carConfig.totalM2WillUse
		let ValidStockSelection = false
		if ( !stock || stock.length === 0 ) {
			ValidStockSelection = false
			return false
		} else if ( !carConfig.selectedStock ) {
			return false
		}
		for ( let i = 0; i < stock.length; i++ ) {
			stock[i]['m2WillUse'] = 0

			if ( !stock[i].m2Used ) {
				stock[i].m2Used = 0
			}
			let stockM2 = stock[i].length * stock[i].height
			let remainingStockM2 = stockM2 - stock[i].m2Used
			if ( totalM2WillUse <= remainingStockM2 + graceM2 ) {
				stock[i].m2WillUse = totalM2WillUse
				totalM2WillUse = 0

				return true
			} else {
				stock[i].m2WillUse = remainingStockM2
				totalM2WillUse -= remainingStockM2
			}
			if ( i === stock.length - 1 && totalM2WillUse > 0 ) {
				return false
			}
		}
		carConfig.ValidStockSelection = ValidStockSelection
		return true
	}
	GetTotalM2WillUse( carConfigs ) {
		carConfigs.forEach( ( config ) => {
			config.totalM2WillUse = 0

			config.selectedParts.forEach( ( part ) => {
				if ( part.partial ) {
					config.totalM2WillUse += ( part.length * part.height ) / 2
				} else if ( part.edge ) {
					config.totalM2WillUse += 0
				} else {
					config.totalM2WillUse += part.length * part.height
				}
			} )
		} )
	}

	//returns a string message with the missing part selection if false.
	IsStockSelectedForParts( parts ) {
		if ( parts && parts.length > 0 ) {
			let partError = ''
			parts.forEach( ( part ) => {
				if ( !part.selectedStock ) {
					partError = 'No stock selected to be used on ' + part.partName
				}
			} )
			return partError
		}
		return 'Please select a part.'
	}
	GetAllUsedStocks( carConfig ) {
		let allStocks = []
		carConfig.forEach( ( config ) => {
			console.log( 'selected stock is' )
			console.log( config.selectedStock )
			allStocks.push( ...config.selectedStock )
		} )
		return allStocks
	}
	GetAllSelectedCarConfigParts( carConfigs ) {
		let allParts = []
		carConfigs.forEach( ( config ) => {
			allParts.push( ...config.selectedParts )
		} )
		return allParts
	}
	CreateCarConfigServiceWork( customerId, installerId, carId, carConfig ) {
		let serviceWork = {
			customerId: customerId,
			installerId: installerId,
			carId: carId,
		}
		let partServiced = []
		carConfig.forEach( ( config ) => {
			config.selectedParts.forEach( ( part ) => {
				let service = {
					carPartId: part._id,
					stockUsed: Object.assign( [{}], config.selectedStock ),
				}
				partServiced.push( service )
			} )
		} )
		serviceWork.partServiced = partServiced
		//add the service_type to the servicework.
		if( carConfig.length >0 ){
			serviceWork.service_type = carConfig[0].service_type

		}
		return serviceWork
	}
	CreateServiceWork( customerId, installerId, carId, selectedParts ) {
		let partsServiced = []
		selectedParts.forEach( ( part ) => {
			let partServiced = {
				carPartId: part._id,
				stockUsed: Object.assign( {}, part.selectedStock[0] ),
			}
			partsServiced.push( partServiced )
		} )
		let serviceWork = {
			customerId: customerId,
			installerId: installerId,
			carId: carId,
			partServiced: partsServiced,
		}
		return serviceWork
	}
}
