<template>
  <v-container>
    <h1>
      {{ stockTableItems.productName }}
      <span v-if="localStockData.totalM2WillUse !== 0" style="color:red">
        ({{ Cm2ToM2(localStockData.totalM2WillUse) }})m remaining.
      </span>
    </h1>

    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th
              class="text-center"
              v-for="(header, index) in stockTableHeaders"
              :key="index"
            >
              {{ $t(header.text) }}
            </th>
          </tr>
        </thead>
        <tbody v-if="hasStock">
          <tr v-for="(stock, index) in localStockData.stock" :key="index">
            <td>
              <input
                :value="stock"
                v-model="localStockData.selectedStock"
                @input="UpdatingCheck(stock)"
                type="checkbox"
              />
            </td>
            <!-- m2WillUse -->
            <td
              :style="selectedStock.isValid ? 'color:green' : 'color:red'"
              v-if="isStockSelected(stock._id)"
            >
              {{ Cm2ToM2(GetM2WillUse(stock._id)) }}m
            </td>
            <td v-else>
              0
            </td>
            <td>
              {{ stock.batch }}
            </td>
            <td>{{ Cm2ToM2(getRemaining(stock)) }}m</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-container>
</template>
<script>
import _ from 'lodash'
import { ServiceUtility } from '../../utility/serviceUtilityV2'

export default {
	props: {
		carConfigProps: Object,
		stockTableHeaders: Array,
		stockTableItems: Object,
	},
	computed: {
		hasStock() {
			return (
				!_.isNil(this.stockTableItems) && !_.isNil(this.stockTableItems.stock)
			)
		},
		selectedStock: {
			get: function() {
				return this.localStockData.selectedStock
			},
			set: async function(newValue) {
				let stockItem = Object.assign(newValue)

				if (stockItem !== undefined)
					this.localStockData.selectedStock = stockItem
			},
		},
	},
	created() {
		this.utility = new ServiceUtility()
		this.InitializeData()
	},
	watch: {
		'config.selectedStock': {
			deep: true,
			handler() {
				console.log('update changes from watcher.')
			},
		},
	},

	data() {
		return {
			utility: '',
			config: [],
			localStockData: '',
			totalM2WillUse: 0,
		}
	},
	methods: {
		isStockSelected(stockId) {
			return (
				this.selectedStock.filter((stock) => stock._id === stockId).length > 0
			)
		},
		ToMeter(itemInCm) {
			return itemInCm / 100
		},
		Cm2ToM2(item) {
			return item / 10000
		},
		getRemaining(stock) {
			let cm2Used = 0
			if (!_.isNil(stock.m2Used)) {
				cm2Used = stock.m2Used
			} else {
				cm2Used = stock.m2Used / 10000
			}
			let remaining = stock.length * stock.height - cm2Used
			return remaining
		},
		GetM2WillUse(stockId) {
			let foundStock = this.selectedStock.find((st) => st._id === stockId)
			if (!_.isNil(foundStock)) {
				//if it is greater than 10000 then it is not m2 but cm2
				if (foundStock.m2WillUse > 10000) {
					return foundStock.m2WillUse
				}
			}
			return foundStock.m2WillUse
		},
		InitializeData() {
			if (!_.isNil(this.stockTableItems)) {
				this.localStockData = Object.assign({}, this.stockTableItems)
				this.totalM2WillUse = this.localStockData.totalM2WillUse
				if (this.localStockData.selectedStock == undefined) {
					this.localStockData.selectedStock = []
				}
				if (!_.isNil(this.localStockData.stock)) {
					this.localStockData.stock = this.localStockData.stock.filter(
						(stock) => stock.length * stock.height - stock.m2Used > 0
					)
				}
				this.EraseM2WillUse()
				this.Calculate()
			}
		},
		EraseM2WillUse() {
			if (this.localStockData.stock) {
				this.localStockData.stock.map((s) => (s.m2WillUse = 0))
				if (this.localStockData.selectedStock) {
					this.localStockData.selectedStock.map(
						(stock) => (stock.m2WillUse = 0)
					)
				}
			}
			this.localStockData.totalM2WillUse = this.totalM2WillUse
		},

		UpdatingCheck(stock) {
			console.log(stock)
			let selectedStock = this.selectedStock.find((s) => s._id === stock._id)
			let exists = selectedStock !== null && selectedStock !== undefined

			if (exists) {
				this.selectedStock = this.selectedStock.filter(
					(st) => st._id !== stock._id
				)
				this.$emit('UpdateStockCalculations', this.localStockData)
			} else {
				let tempSelectedStock = this.selectedStock
				tempSelectedStock.push(stock)
				this.selectedStock = tempSelectedStock
        
			}
			this.Calculate()
      
		},
		Calculate() {
			this.EraseM2WillUse()
			let { totalM2WillUse } = this.localStockData
			let graceCm2 = 200

			// this.selectedStock.forEach((stock, index) => {
			if (_.isArray(this.selectedStock) && this.selectedStock.length > 0) {
				_.forEach(this.selectedStock, (stock) => {
					let index = _.indexOf(this.selectedStock, stock)
					if (totalM2WillUse === 0) {
						//done, exit array
						return false
					}

					let stockRemaining = this.getRemaining(stock)
					let stockHasEnough = stockRemaining + graceCm2 >= totalM2WillUse
					if (stockHasEnough) {
						stock.m2WillUse = totalM2WillUse
						totalM2WillUse = 0
						this.selectedStock.isValid = true
						return false
					} else {
						//if there isn't enough, check if this is the last stock
						if (index === this.selectedStock.length - 1) {
							//there isn't enough and it's last stock.
							totalM2WillUse = totalM2WillUse - stockRemaining
							stock.m2WillUse = stockRemaining
							this.selectedStock.isValid = false
						} else {
							//there isn't enough,, but there is still stock left, take what you can
							totalM2WillUse = totalM2WillUse - stockRemaining
							stock.m2WillUse = stockRemaining
						}
					}
				})
			}
			this.localStockData.totalM2WillUse = totalM2WillUse
			this.$emit('UpdateStockCalculations', this.localStockData)
		},
	},
}
</script>
