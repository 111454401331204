import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import createPlugin from 'logrocket-vuex'
import LogRocket from 'logrocket'
import { setDocumentLang } from '../locales/document'

const logrocketPlugin = createPlugin( LogRocket )

Vue.use( Vuex )
const rtlLanguages = ['ar', 'he', 'fa', 'ur']
const ModifyUserLocale = async ( user_id, locale ) => {
	let response = await axios.post(
		process.env.VUE_APP_BASEURL +
		'user/modify/locale/' +
		user_id,
		{ locale: locale }
	)
	console.log( 'locale change response', response )
}
export default function createStore( vuetify, i18n ){
	return new Vuex.Store( {
		plugins: [logrocketPlugin],
		state: {
			status: '',
			locale: localStorage.getItem( 'locale' ) || 'en',
			token: localStorage.getItem( 'token' ) || null,
			user: localStorage.getItem( 'user' ) || null,
			role: localStorage.getItem( 'role' ) || null,
			userId: localStorage.getItem( 'userId' ) || null,
			showSplash: localStorage.getItem( 'showSplash' ) || false,
		},
		getters: {
			get_locale: ( state ) => state.locale,
			logo_url: ( state ) => {
				if( state.user ){
					let user = JSON.parse( state.user )
					return user.logoUrl
				} 
				return null
			},
			seller_id: ( state ) => {
				if( state.user ){
					let user = JSON.parse( state.user )
					return user.seller_id
				} 
				return null} ,
			isLoggedIn: ( state ) => !!state.token,
			authStatus: ( state ) => state.status,
			getUserRole: ( state ) => state.role,
			getUserId: ( state ) => state.userId,
			getUser: ( state ) => ( state.user ? JSON.parse( state.user ) : '' ),
			getToken: ( state ) => state.token,
		},
		mutations: {
			set_locale( state, locale ) {
				console.log( 'Setting locale to: ' + locale )
				setDocumentLang( locale )
				vuetify.framework.lang.current = locale
				i18n.locale = locale
				vuetify.framework.rtl = rtlLanguages.includes( locale )
				if ( state.user ) {
					let user = JSON.parse( state.user )
					user.locale = locale
					localStorage.setItem( 'user', JSON.stringify( user ) )
				}
				localStorage.setItem( 'locale', locale )
				// localStorage.setItem('user',JSON.stringify(user));
			},
			auth_request( state ) {
				state.status = 'loading'
			},
			auth_success( state, tokenUser ) {
				state.status = 'success'
				state.user = JSON.stringify( tokenUser.user )
				state.token = tokenUser.token
				state.role = tokenUser.role
				state.userId = tokenUser.user._id
			},
			auth_error( state ) {
				state.status = 'error'
			},
			auth_logout( state ) {
				state.showSplash = false
				state.status = ''
				state.token = ''
				state.user = ''
				state.role = ''
				state.userId = ''
			},
				
		},
		actions: {
			initializeLocale( { commit, getters } ) {
				let locale = getters.get_locale
				if ( locale ) {
					commit( 'set_locale', locale )
				}
			},
			setLocale( { commit, getters }, locale ) {
				console.log( 'vuetify.lang: ' , vuetify.framework.lang.current )
				console.log( 'locale: ' + locale )
				console.log( 'i18n.locale: ' + i18n.locale )
				if ( locale !== vuetify.framework.lang.current ) {
					const user = getters.getUser
					if ( user ) {
						if ( user.locale !== locale ) {
							ModifyUserLocale( user._id, locale )
							
						}
					}
				}
				commit( 'set_locale', locale )
			},
			async getUserAsync( { commit, state } ) {
				return state.user
			},
			async login( { commit }, userCred ) {
				try {
					commit( 'auth_request' )
	
					//sanitize username to be case sensitive and truncate white spaces
					userCred.auth.username = userCred.auth.username.toLowerCase().trim()
					let response = await axios.post(
						process.env.VUE_APP_BASEURL + 'login',
						userCred
					)
	
					if ( response && response.statusText === 'OK' ) {
						const token = await response.data.token
						const user = await response.data.user
						const role = await user.role
						localStorage.setItem( 'token', token )
						localStorage.setItem( 'role', user.role )
						localStorage.setItem( 'user', JSON.stringify( user ) )
						localStorage.setItem( 'userId', user._id )
						axios.defaults.headers.common['Authorization'] = token
						let tokenUser = { token: token, user: user, role: role }
						await commit( 'auth_success', tokenUser )
						return true
	
					}
					else {
						return false
					}
				} catch ( err ) {
					console.log( 'Error:' )
					console.log( err )
					return false
				}
			},
			logout( { commit } ) {
				return new Promise( ( resolve, reject ) => {
					commit( 'auth_logout' )
					localStorage.removeItem( 'token' )
					localStorage.removeItem( 'user' )
					localStorage.removeItem( 'role' )
					localStorage.removeItem( 'userId' )
					delete axios.defaults.headers.common['Authorization']
					resolve()
				} )
			},
			async checkRole( state, roles ) {
				let role = state.getters.getUserRole
				return roles.some( ( r ) => r === role.toLowerCase() )
			}
		},
		modules: {},
	} )

} 
