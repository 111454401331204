<template>
    <v-container class="pa-6">
      <v-col cols="12" md="6" style="margin: auto">
        <v-form v-on:submit.prevent="Login" v-model="isValid">
          <v-text-field
            style="text-align: center"
            outlined
            required
            v-model="username"
            :label="$t('username')"
            :rules="rules"
          ></v-text-field>
          <v-text-field
            outlined
            required
            v-model="password"
            :label="$t('password')"
            type="password"
            :rules="rules"
          ></v-text-field>
          <v-btn
            color="green"
            style="color: white"
            class="col-12"
            type="submit"
            :loading="loading"
            :disabled="!isValid"
            >{{ $t("login") }}</v-btn
          >
        </v-form>
      </v-col>
    </v-container>
</template>

<script>
import { bus } from '../main'
import Splash from '../components/Splash'
export default {
	name: 'Login',
	components: { Splash },
	data() {
		return {
			username: '',
			password: '',
			rules: [( value ) => !!value || 'Required'],
			isValid: true,
			loading: false,
			// showSplash: false,
			timeout: 2000,
		}
	},
	methods: {
		async SetupSplash() {
			// this.showSplash = true;
			await setTimeout( () => {
				// this.showSplash = false;
			}, this.timeout )
		},
		async Login() {
			try {
				this.loading = true
				// this.$store.state.status='loading'
				this.$store.state.showSplash = true
				let x = await this.$store.dispatch( 'login', {
					auth: { username: this.username, password: this.password },
				} )
				if ( x ) {

					console.log( 'logged in' )
					console.log( x )
					setTimeout( async () => {
						await this.$router.replace( '/' )

						this.$store.state.showSplash = false
					}, this.timeout )
				} else {
					bus.$emit(
						'snack-error',
						'Error Loggin in. Please check username and password.'
					)
					this.$store.state.showSplash = false
					this.loading = false
				}
			} catch ( err ) {
				this.loading = false
				bus.$emit( 'snack-error', 'The username or password is incorrect' )
				console.log( err )
			}
		},
	},
}
</script>
