<script>
import { Bar, Line, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins
export default {
	extends: Line,
	mixins: [reactiveProp],
	name: 'WorkHistoryChart',
	props: ['chartData', 'options'],
	mounted() {
		this.renderChart(this.chartData, this.options)
	},
}
</script>

<style scoped></style>
