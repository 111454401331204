<!--suppress SpellCheckingInspection -->
<template>
  <v-container>
    <v-dialog v-model="showSuccessDialog" width="max-content">
      <v-layout align-center justify-center align-content-center>
        <v-card>
          <v-card-title class="text-center justify-center"
            >Success!</v-card-title
          >
          <v-card-text>
            <v-row>
              <v-col class="text-center">
                <v-icon style="font-size: 193px" color="green" large
                  >mdi-check-circle-outline</v-icon
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col class="text-center">
                {{ $t("service_work_created") }}
              </v-col>
            </v-row>
            <v-row>
              <v-col> {{ $t("redirect") }} </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="justify-center">
            <v-btn outlined color="green" @click="Reload()">Ok</v-btn>
          </v-card-actions>
        </v-card>
      </v-layout>
    </v-dialog>
    <!--        {{carConfigs}}-->
    <v-btn class="float-left" icon color="red" @click="$emit('back')">
      <v-icon>mdi-close</v-icon>
    </v-btn>
    <v-container class="text-center" v-show="!nextPage" v-if="resetToggle">
      <v-btn-toggle
        v-if="installerStocks"
        style="text-align: center"
        color="green"
      >
        <v-btn
          outlined
          :disabled="!HasStock('PPF')"
          elevation="3"
          @click="selectedProduct = ppfStocks"
          >{{ $t("ppf") }}</v-btn
        >
        <v-btn
          outlined
          :disabled="!HasStock('Window Film')"
          elevation="3"
          @click="selectedProduct = filmStocks"
          >{{ $t("window_film") }}</v-btn
        >
        <v-btn
          outlined
          :disabled="!HasStock('Safety')"
          elevation="3"
          @click="selectedProduct = safetyStocks"
          >{{ $t("safety") }}</v-btn
        >
      </v-btn-toggle>
      <v-alert
        v-if="serviced_today"
        color="yellow"
        style="margin-top: 10px"
        ><b>{{ $t("service_done_warning") }}</b></v-alert
      >
      <CarConfiguration
        v-if="
          selectedProduct !== null &&
          selectedProduct !== undefined &&
          selectedProduct.length > 0
        "
        :parts="carBodyParts"
        :products="selectedProduct"
        @UpdateCarConfigs="UpdateConfigs"
        ref="carConfigRef"
      ></CarConfiguration>
    </v-container>
    <v-container v-if="nextPage">
      {{ $t("select_stock") }}
      <ServiceStockSelection
        :car-configs="carConfigs"
        @UpdateValidStock="UpdateValidStock"
        @UpdateStockFromSelection="UpdateStockFromSelection"
      ></ServiceStockSelection>
    </v-container>
    <v-btn
      v-if="!nextPage"
      :disabled="!nextable"
      outlined
      color="green"
      @click="ToggleNextPage()"
      >{{ $t("next") }}</v-btn
    >
    <span v-else>
  
      <v-btn
        :disabled="!isAllValid()"
        @click="TrySubmit"
        outlined
        color="green"
        style=""
        >{{ $t("submit") }}</v-btn
      >
 
          <v-btn outlined color="red" @click="nextPage = false">{{
        $t("back")
      }}</v-btn>
    </span>
  </v-container>
</template>

<script>
import { ServiceUtility } from '../../utility/serviceUtilityV2.js'
import { bus } from '../../main'
import axios from 'axios'
import CarConfiguration from './CarConfiguration'
import ServiceStockSelection from './ServiceStockSelection'
import _ from 'lodash'

export default {
	name: 'ServiceWorkV5',
	//props car contains carId, customerId, installerId
	props: ['serviceWorkProps'],
	components: { ServiceStockSelection, CarConfiguration },
	computed: {
		showServicedWarning(){
			return this.serviced_today
		},
		isNextable() {
			return this.nextable
		},
		getCarConfigs(){
			return this.carConfigs
		}
	},
	async created() {
		this.utility = new ServiceUtility()
		await this.InitializeData()
		// console.log( 'should show service warning?  ? ? ? ? ', this.showServicedWarning )
	},

	data() {
		return {
			serviced_today: false,
			hasPPFStock: false,
			showSuccessDialog: false,
			resetToggle: true,
			canSubmit: false,
			carConfigs: [],
			carConfigsSelections: [],
			nextPage: false,
			nextable: false,
			selectedProduct: [],
			firstPPFPart: false,
			utility: '',
			showAlert: false,
			alertMessage: '',
			chosenParts: [],
			carData: '',
			carBodyParts: [],
			installerStocks: '',
			filmParts: [],
			ppfParts: [],
			safetyParts: [],
			stockTableHeaders: '',
			partTableHeaders: '',
			filmStocks: [],
			ppfStocks: [],
			safetyStocks: [],
			showSameProduct: false,
			showFirstDialog: false,
			currentDialogPart: [],
			currentDialogStock: [],
			// showServicedWarning: false,
			allValidStocks: false,
		}
	},
	methods: {
		isAllValid() {
			let valid = true
			this.carConfigs.forEach( ( config ) => {
				if ( valid ) {
					if ( !_.isNil( config.selectedStock ) ) {
						//if there are any invalid items in the stock, return false.
						// let invalidConfigs = config.selectedStock.filter(
						//   (stock) => !stock.isValid
						// );
						if ( !config.selectedStock.isValid ) {
							// console.log(`stocks invalid.`);
							valid = false
						} else {
							// console.log("REALLY TRUE");
							valid = true
						}
					} else {
						// console.log("config.selectedStock is nill");
						valid = false
					}
				}
			} )
			return valid
		},
		UpdateStockFromSelection( carConfig ) {

			this.allValidStocks = false

			let currentConfig = this.carConfigs.find(
				( config ) => config.product === carConfig.productId
			)
			let index = _.indexOf( this.carConfigs, currentConfig )
			//if current config has the same selected stock length.
 

			if ( !_.isNil( currentConfig ) ) {
				// currentConfig.selectedStock.isValid = carConfig.selectedStock.isValid;
				this.carConfigs[index].selectedStock.isValid =
          carConfig.selectedStock.isValid
			}
			if( this.carConfigs[index].selectedStock.length > carConfig.selectedStock.length )
			{
				//find which stock was removed.
				let removedItem =this.carConfigs[index].selectedStock.filter( stock=> !carConfig.selectedStock.includes( stock ) )
				let indexOfRemovedStock = _.indexOf( this.carConfigs[index].selectedStock, ...removedItem )
				let modifiedConfigs= Object.assign( [],this.carConfigs )
				modifiedConfigs[index].selectedStock = modifiedConfigs[index].selectedStock.splice( indexOfRemovedStock,1 )
				this.UpdateConfigs( modifiedConfigs )
       

			}

			this.allValidStocks = this.isAllValid()
			this.$forceUpdate()
		},
		async IsAlreadyServiced() {
			console.log( 'checking if serviced today' )
			//get all serviceworks done by installer
			let response = await axios.get(
				process.env.VUE_APP_BASEURL + 'servicework/doneToday' ,
				{
					params: {
						carId: this.serviceWorkProps.carId
					}
				}
			)
			let { data } = response

			if (
				data !== null &&
        data !== undefined &&
        data !== '' ) {
				console.log( 'got response data!!!', data )
				if( data.serviced_today ){
					this.serviced_today = data.serviced_today
					console.log( 'serviced_tokday? ', data.serviced_today )
					// return data.serviced_today > 0
				}
				console.log( 'is serviced tokday? passed' )
				//if there are any servicework done, check if there was one done today.
				// let today = new Date()
				// this.serviced_today = data.serviced_today

				return this.serviced_today
			}
			return false
		},
		HasStock( category ) {
			return this.installerStocks.some(
				( stock ) => stock.productId.category === category
			)
		},
		UpdateValidStock( config, isValid ) {
			let currentConfig = this.carConfigs.find( ( conf ) => conf === config )
			currentConfig.ValidStockSelection = isValid
			this.$forceUpdate()
			this.canSubmit = this.CanSubmit()
		},
		UpdateConfigs( configs ) {
			console.log( 'updating configs........' )
			this.carConfigs = configs
			console.log( this.carConfigs.selectedPackage )
			this.utility.GetTotalM2WillUse( this.carConfigs )
			this.IsNextable()
			this.canSubmit = this.CanSubmit()
		},

		IsNextable() {
			if ( this.carConfigs.length > 0 ) {
				let carConfigsSelected = this.carConfigs.filter(
					( config ) => config.selectedParts.length > 0
				)
				if ( this.carConfigs.length === carConfigsSelected.length ) {
					this.nextable = true
				} else {
					this.nextable = false
				}
			} else {
				this.nextable = false
			}
		},
		ToggleNextPage() {
			this.nextPage = !this.nextPage
			this.carConfigsSelections = []
		},
		SplitPartsByCategory( newParts ) {
			let tempfilmParts = this.utility.GetPartsByCategory(
				newParts,
				'Window Film'
			)
			if (
				this.filmStocks &&
        this.filmStocks.length > 1 &&
        tempfilmParts.length === 1
			) {
				this.firstFilmPart = false
			}
		},
		UpdateSelectedParts( updatedParts ) {
			this.chosenParts = updatedParts

			this.SplitPartsByCategory( updatedParts )
			this.filmParts = this.utility.GetPartsByCategory(
				this.chosenParts,
				'Window Film'
			)
			this.ppfParts = this.utility.GetPartsByCategory( this.chosenParts, 'PPF' )
			this.safetyParts = this.utility.GetPartsByCategory(
				this.chosenParts,
				'Safety'
			)
		},
		async InitializeData() {
			this.resetToggle = true
			this.InitializeTableHeaders()
			console.log( 'serviceWorkProps', this.serviceWorkProps )
			if ( this.serviceWorkProps ) {
				
				this.carData = await this.utility.GetCarData(
					this.serviceWorkProps.carId
				)
				await this.IsAlreadyServiced()
				// console.log( 'carbody time comes from carbodyparts', this.carData )
				this.carBodyParts = this.carData.body.parts
				this.installerStocks = await this.utility.GetInstallerStock(
					this.serviceWorkProps.installerId
				)
				await this.FilterStocks()
			}
		},
		ValidateStockUsage( stock ) {
			let validated =
        stock.m2WillUse < stock.length * stock.height - stock.m2Used
			if ( !validated ) {
				this.showAlert = true
				this.alertMessage = 'Please make sure you have enough in stock.'
			}
			return validated
		},
		FilterStocks() {
			this.filmStocks = this.utility.GetStockByCategory(
				this.installerStocks,
				'Window Film'
			)
			this.filmStocks = this.filmStocks.filter(
				( stock ) => stock.length * stock.height - stock.m2Used > 0
			)
			this.ppfStocks = this.utility.GetStockByCategory(
				this.installerStocks,
				'PPF'
			)
			this.ppfStocks = this.ppfStocks.filter(
				( stock ) => !stock.m2Used || stock.length * stock.height - stock.m2Used > 0 && !stock.isExpired
			)
			this.safetyStocks = this.utility.GetStockByCategory(
				this.installerStocks,
				'Safety'
			)
			this.safetyStocks = this.safetyStocks.filter(
				( stock ) => stock.length * stock.height - stock.m2Used > 0
			)
		},
		InitializeTableHeaders() {
			this.partTableHeaders = [
				{ text: 'part', value: 'partName' },
				{ text: 'Product Selection', value: 'productSelection' },
			]

			this.stockTableHeaders = [
				{ text: 'partName', value: 'partname', sortable: false },
				{ text: 'action', value: 'actions', sortable: false },
				{ text: 'stock_name', value: 'productId.name' },
				{ text: 'category', value: 'productId.category' },
				{ text: 'WillUse', value: 'm2WillUse' },
				{ text: 'stock_serial', value: 'serial' },
				{ text: 'batch', value: 'batch' },
				{ text: 'totalM2', value: 'totalM2' },
				{ text: 'M2 Used', value: 'm2Used' },
				{ text: 'remaining', value: 'remaining' },
			]
		},
		async TrySubmit() {
			try {
				let carConfigs = Object.assign( [{}], this.carConfigs )
				//first add all m2WillUse to m2Used for the selected stocks.
				carConfigs.forEach( ( config ) => {
					//remove unneeded stocks. (m2WillUse=0)
					config.selectedStock.forEach( ( stock, index ) => {
						if ( !stock.m2WillUse || stock.m2WillUse <= 0 ) {
							//check if any part selected was an edge, in that case do not delete the empty stock.
							if ( !config.selectedParts.some( ( part ) => part.edge ) )
								delete config.selectedStock[index]
						} else {
							//add m2WillUse to m2Used if not deleted.
							stock.m2Used += stock.m2WillUse
						}
					} )
				} )
				if (
					this.serviceWorkProps.customerId !== undefined &&
          this.serviceWorkProps.installerId !== undefined &&
          this.serviceWorkProps.carId !== undefined
				) {
					let serviceWork = this.utility.CreateCarConfigServiceWork(
						this.serviceWorkProps.customerId,
						this.serviceWorkProps.installerId,
						this.serviceWorkProps.carId,
						carConfigs
					)
			
					let allStocks = await this.utility.GetAllUsedStocks( carConfigs )

				
					//on success post service work
					this.carConfigs = carConfigs
					let serviceWorkResponse = await axios.post(
						process.env.VUE_APP_BASEURL + 'serviceWork/',
						serviceWork
					)
					//only if servicework was posted successfully, update stock.
					if ( serviceWorkResponse && serviceWorkResponse?.status === 200 ) {
					// update stock db
						let modifyStockResponse = await axios.put(
							process.env.VUE_APP_BASEURL + 'stock/bulkModify',
							allStocks
						)
						if (
							!_.isNil( modifyStockResponse.status ) &&
							modifyStockResponse.status === 200
						) {
							
							this.showSuccessDialog = true
							// setTimeout( () => this.Reload(), 2000 )
						}
						else {
							bus.$emit( 'snack-error', 'error sending serviceWork' )
							console.log( modifyStockResponse.data )
							throw new Error( 'error sending ServiceWork - modifyStockResponse -' )
						}
					}
					else{
						console.error( 'error sending serviceWork', serviceWorkResponse )
						throw new Error( 'error sending ServiceWork - ' )
						
					} 
				} else {
					throw new Error(
						'Issue with an undefined property in servicework when posting.'
					)
				}
			} catch ( e ) {
				bus.$emit( 'snack-error', 'an error occored while submitting work' )
				console.log( e )
				throw new Error( e )
			}
		},

		Reload() {
			this.$router.go( this.$router.currentRoute )
		},
		ShowDialog( currentPart, currentStock ) {
			this.currentDialogPart = currentPart
			this.currentDialogStock = currentStock
			this.showFirstDialog = true
		},
		ValidateStocks() {
			//checks if there are any errors visible due to calculations, if there is return false;
			if ( this.showAlert ) {
				bus.$emit(
					'snack-success',
					'Please check stock and assign batch with a correct length'
				)
				return false
			}

			//checks if all stocks are selected for each part chosen.
			let stocksSelected
			if ( this.filmParts && this.filmParts.length > 0 ) {
				stocksSelected = this.ValidateStocksForParts( this.filmParts )
				if ( !stocksSelected ) {
					return false
				}
			}
			if ( this.ppfParts && this.ppfParts.length > 0 ) {
				stocksSelected = this.ValidateStocksForParts( this.ppfParts )
				if ( !stocksSelected ) {
					return false
				}
			}
			if ( this.safetyParts && this.safetyParts.length > 0 ) {
				stocksSelected = this.ValidateStocksForParts( this.safetyParts )
				if ( !stocksSelected ) {
					return false
				}
			}
			return true
		},
		//Validation to see if we can submit yet.
		CanSubmit() {
			let result = false
			if ( this.carConfigs ) {
				result = true
				for ( let i = 0; i < this.carConfigs.length; i++ ) {
					let config = this.carConfigs[i]
					if ( config.selectedStock && config.selectedStock.length > 0 ) {
						if ( !config.ValidStockSelection ) {
							result = false
							break
						}
					} else {
						result = false
						break
					}
				}
			}
			return result
		},
		//checks if all chosen parts have stocks selected.
		ValidateStocksForParts( parts ) {
			let errorMessage = this.utility.IsStockSelectedForParts( parts )
			if ( errorMessage ) {
				bus.$emit( 'snack-error', errorMessage )
				return false
			}
			return true
		},
	},
}
</script>
