<template>
	<v-container>
		<v-container>
			<v-row class="justify-center">
				<v-col cols="12" sm="auto" class="pa-0">
					<v-select style="width: fit-content" outlined :items="products" v-model="selectedProduct"
						item-text="productId.name" item-value="productId._id" :label="$t('select_product')"></v-select>
				</v-col>
			</v-row>
		</v-container>
		<v-container>
			<span v-if="$vuetify.breakpoint.mdAndUp">
				<v-btn-toggle v-model="selectedPackage" group color="purple"
					v-if="selectedProduct && selectedProductCategory === 'PPF'">
					<v-btn v-for="(option, index) in packages[0].options" :value="option.value" :key="index">{{
						$t(option.name) }}</v-btn>
				</v-btn-toggle>
			</span>
			<span v-else>
				<v-select v-if="selectedProduct && selectedProductCategory === 'PPF'" v-model="selectedPackage"
					:items="packages[0].options" label="Select Package" :item-text="getPackageLocaleName">
					<template v-slot:item="{ item }"> {{ $t(item.value) }} </template>
				</v-select>
			</span>

			<v-btn-toggle v-model="selectedPackage" v-if="selectedProduct && selectedProductCategory === 'Window Film'">
				<v-btn v-for="(option, index) in packages[1].options" :value="option.value" :key="index">{{ $t(option.name)
				}}</v-btn>
			</v-btn-toggle>
		</v-container>
		<v-row> </v-row>
		<v-row v-for="(config, index) in carConfigs" :key="config.product">
			<v-col>
				<CarProductSelection :body-parts="parts" :product="config.product" :select-parts="config.selectParts"
					:stock="config.stock" :selected-package="config.selectedPackage"
					:block-windshield="config.blockWindshield" :previously-selected="config.previouslySelectedParts"
					@remove="RemoveCarProductSelection($event, config)" @partsUpdated="UpdateParts($event, config)">
				</CarProductSelection>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import CarProductSelection from './CarProductSelection'
import { ServiceUtility } from '../../utility/serviceUtilityV2'
import axios from 'axios'
import { PACKAGES } from '../../constants'
export default {
	name: 'CarConfiguration',
	components: { CarProductSelection },
	props: ['parts', 'products'],
	async created() {
		if ( this.parts ) {
			const parts_response = await axios.get(
				`${process.env.VUE_APP_BASEURL}v2/Setting/carbodies`  ///${this.parts._id}`
			)
			// console.log('parts response', parts_response)
		}
		this.utility = new ServiceUtility()
	},
	computed: {
		selectedProductCategory: function () {
			if ( this.selectedProduct ) {
				return this.GetProductCategory( this.selectedProduct )
			}
			return null
		},
		safetySelected: function () {
			let safety = this.carConfigs.find( ( config ) =>
				this.IsSafety( config.product )
			)
			return safety != null
		},
	},
	watch: {
		selectedPackage: function () {
			this.AddConfig()
		},

		carConfigs: {
			deep: true,
			handler() {
				// this.carConfigs.selectedPackage = this.selectedPackage
				this.$emit( 'UpdateCarConfigs', this.carConfigs )
			},
		},
		previouslySelectedParts: function () {
			this.carConfigs.map( ( conf ) => {
				conf.previouslySelectedParts = this.previouslySelectedParts
			} )
		},
		products: function () {
			this.selectedProduct = ''
		},
	},
	data() {
		return {
			selectedPackage: '',
			packages: [
				{
					category: 'PPF',
					options: [
						{ name: 'normal', value: 'Normal' },
						{ name: 'full_body', value: 'full_body' },
						{ name: 'full_frontal', value: 'full_frontal' },
						{ name: 'half_quarter_hood', value: 'half_quarter_hood' },
					],
				},
				{
					category: 'Window Film',
					options: [
						{ name: 'normal', value: 'Normal' },
						{ name: 'full_window_film', value: 'window_film' },
					],
				},
			],
			utility: '',
			carConfigs: [],
			selectedProduct: '',
			previouslySelectedParts: [],
		}
	},
	methods: {
		getPackageLocaleName( item ) {
			return this.$t( item.name )
		},
		GetProductCategory( productId ) {
			let prod = this.products.find(
				( product ) => product.productId._id === productId
			)
			return prod.productId.category
		},
		async AddConfig() {
			// console.log( 'adding config' )
			// console.log( 'config before error', this.carConfigs )

			if ( this.selectedProduct ) {
				let selectedProductStock = await this.GetStocksByProductId(
					this.selectedProduct
				)
				let currentCarConfig = {
					product: this.selectedProduct,
					stock: selectedProductStock,
					selectedParts: [],
					previouslySelectedParts: this.previouslySelectedParts
				}

				currentCarConfig.blockWindshield = this.ShouldBlockWindshield(
					selectedProductStock
				)
				if ( this.selectedPackage ) {
					if ( this.selectedPackage && this.selectedPackage.toLowerCase() !== 'normal' ) {
						const package_body = PACKAGES[this.selectedPackage.toUpperCase()]
						currentCarConfig.selectParts = this.parts.filter( part => {
							return package_body.parts.some( pkgPart => pkgPart.partName === part.partName && pkgPart.partCategory === part.partCategory )
						} ).map( filtered_part => {
							const p = package_body.parts.find( pkgPart => pkgPart.partName === filtered_part.partName && pkgPart.partCategory === filtered_part.partCategory )
							if ( p ) {
								filtered_part.partial = p.partial || false
								filtered_part.edge = p.edge || false
							}
							return filtered_part
						} )
					}
				}
				//add the selected package to the config.
				currentCarConfig.service_type = this.selectedPackage
				this.carConfigs.push( currentCarConfig )
				this.selectedProduct = ''
				this.selectedPackage = ''
			}

		},
		ShouldBlockWindshield( currentStock ) {
			let result = false
			let windshieldSelected = this.carConfigs.filter( ( config ) =>
				config.selectedParts.some( ( part ) => part.partName === 'Windshield' )
			)
			if ( windshieldSelected ) {
				windshieldSelected.forEach( ( windshieldSelection ) => {
					if (
						windshieldSelection.stock[0].productId.category ===
						currentStock[0].productId.category
					) {
						result = true
					}
				} )
			}
			return result
		},
		IsSafety( productId ) {
			let product = this.products.find(
				( product ) => product.productId._id === productId
			)
			if ( product ) {
				return product.productId.category === 'Safety'
			}
			return false
		},
		async GetStocksByProductId( productId ) {
			// get them from backend instead.
			const filteredStocks = await axios.get(
				`${process.env.VUE_APP_BASEURL}stock/useableProducts/${productId}`
			)
			console.log( 'filteredStocks', filteredStocks )
			return filteredStocks.data
		},
		RemoveCarProductSelection( productId, config ) {
			//reset partial and edge fields in the parts of the rmeoved config.
			let configToRemove = this.carConfigs.filter(
				( config ) => config.product === productId
			)
			configToRemove[0].selectedParts.forEach( ( part ) => {
				part.partial = false
				part.edge = false
			} )

			this.carConfigs = this.carConfigs.filter(
				( config ) => config.product !== productId
			)

			this.previouslySelectedParts = this.previouslySelectedParts.filter(
				( ps ) => !config.selectedParts.some( ( p ) => p._id === ps._id )
			)
		},
		UpdateParts( newParts, config ) {
			config.selectedParts = newParts
			this.previouslySelectedParts = []
			this.carConfigs.forEach( ( carconf ) => {
				this.previouslySelectedParts.push( ...carconf.selectedParts )
			} )
			this.utility.GetTotalM2WillUse( this.carConfigs )
		},
	},
}
</script>
