<template>
  <!-- <v-container> -->
    <v-container >
      <h1 class="text-center">Welcome {{ $store.getters.getUser.name }}</h1>
      <v-row >
        <v-col cols="12" md="4">
          <v-skeleton-loader
            v-if="loading_dashData"
            type="image"
            :max-width="cardWidth"
            min-height="100%"
            min-width="fit-content"
            max-height="106px"
            class="ma-auto"
          ></v-skeleton-loader>
          <v-card
            v-else
            style="margin: auto; color: white; white-space: nowrap"
            color="orange"
            raised
            :max-width="cardWidth"
            min-height="100%"

          >
            <v-card-title class="text-center justify-center">
              <span> Service Works This Month</span></v-card-title
            >
            <v-card-text
              class="text-center justify-center cardlet"
              style="font-size: large"
            >
              <span style="color: white">{{ mostServiceWork }}</span>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="4">
          <v-skeleton-loader
            v-if="loading_dashData"
            type="image"
            :max-width="cardWidth"
            min-height="100%"
            min-width="fit-content"
            max-height="106px"
            class="ma-auto"
          ></v-skeleton-loader>
          <v-card
            v-else
            style="
              margin: auto;
              color: white;
              white-space: nowrap;
              height: 100%;
            "
            color="blue"
            raised
            :max-width="cardWidth"
          >
            <v-card-title class="text-center justify-center">
              Service Works Today</v-card-title
            >
            <v-card-text
              class="text-center justify-center cardlet"
              style="font-size: large"
            >
              <span style="color: white">{{ servicesToday }}</span>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="4">
          <v-skeleton-loader
            v-if="loading_dashData"
            type="image"
            :max-width="cardWidth"
            min-height="100%"
            min-width="fit-content"
            max-height="106px"
            class="ma-auto"
          ></v-skeleton-loader>
          <v-card
            v-else
            style="margin: auto; color: white; "
            color="green"
            raised
            :max-width="cardWidth"
          >
            <v-card-title class="text-center justify-center">
              Top Product Sold</v-card-title
            >
            <v-card-text
              class="text-center justify-center cardlet"
             
            >
              <span style="color: white">{{ topProductSold }}</span>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col :cols="12" md="12">
          <WorkHistoryChart
            :chartData="chartData"
            :options="options"
            style="max-width: 300px; margin: auto"
          ></WorkHistoryChart>
        </v-col>
      </v-row>
      <v-row>
        <v-col :cols="12" md="6">
          <v-card style="border: 1px solid black">
            <v-card-title>Product Overview</v-card-title>
            <v-card-text>
              <v-row style="padding-left: 5px; padding-right: 5px">
                Total Products: <v-spacer></v-spacer><b>{{ totalProducts }}</b>
              </v-row>
              <v-row style="padding-left: 5px; padding-right: 5px">
                Total Stock: <v-spacer></v-spacer> <b>{{ totalStock }}</b>
              </v-row>
              <v-row style="padding-left: 5px; padding-right: 5px">
                Low Stock Items:<v-spacer></v-spacer> <b>{{ lowStockItems }}</b>
              </v-row>
              <v-row style="padding-left: 5px; padding-right: 5px">
                Stocks Sold this month: <v-spacer></v-spacer>
                <b>{{ soldThisMonth }}</b>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col :cols="12" md="6">
          <v-card style="border: 1px solid black">
            <v-card-title>Service Overview</v-card-title>
            <v-card-text>
              <v-row style="padding-left: 5px; padding-right: 5px">
                Total Services: <v-spacer></v-spacer><b>{{ totalServices }}</b>
              </v-row>
              <v-row style="padding-left: 5px; padding-right: 5px">
                Services This Month: <v-spacer></v-spacer
                ><b>{{ serviceWorksThisMonth.length }}</b>
              </v-row>
              <v-row style="padding-left: 5px; padding-right: 5px">
                Service Today: <v-spacer></v-spacer><b>{{ servicesToday }}</b>
              </v-row>
              <v-row style="padding-left: 5px; padding-right: 5px">
                Total Customers: <v-spacer></v-spacer
                ><b>{{ totalCustomers }}</b>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  <!-- </v-container> -->
</template>

<script>
import ServiceInstaller from '../components/ServiceInstaller'
import QR from '../components/QR'
import axios from 'axios'
import WorkHistoryChart from '../components/WorkHistoryChart'
import _ from 'lodash'

export default {
	name: 'AdminDash',
	components: { WorkHistoryChart, ServiceInstaller, QR },
	async created() {
		await this.GetAdminDash()
		await this.OrganizeWorkHistory()
	},
	mounted() {},
	watch: {
		organizedSW: function () {
			this.InitializeChartData()
		},
	},
	data() {
		return {
			loading_dashData: true,
			productData: '',
			serviceData: '',
			cardWidth: '40vh',
			cardHeight: '15vh',
			text: '',
			//product data
			totalProducts: 'Unavailable',
			totalStock: 'Unavailable',
			soldThisMonth: 'Unavailable',
			stockSoldThisMonth: 'Unavailable',
			lowStockItems: 'Unavailable',
			topPurchaser: '\'Unavailable\'',
			topProductSold: '\'Unavailable\'',
			//ServiceWork data
			serviceWorksThisMonth: '',
			servicesToday: '',
			totalServices: '',
			mostServiceWork: '',
			totalCustomers: '',

			organizedSW: [],
			chartData: null,
			options: {
				scales: {
					yAxes: [
						{
							ticks: {
								min: 0,
								stepSize: 1,
								// maxTicksLimit: 0
							},
						},
					],
				},
			},
		}
	},
	methods: {
		GetValue( item ) {
			return item ? item : 0
		},
		async GetAdminDash() {
			this.loading_dashData = true
			await this.GetProductDash()
			await this.GetWorkService()
			this.loading_dashData = false
		},
		async GetProductDash() {
			let productAdminResponse = await axios.get(
				process.env.VUE_APP_BASEURL + 'product/admin/dashData'
			)
			if (
				!_.isNil( productAdminResponse ) &&
        productAdminResponse.statusText === 'OK' &&
        !_.isNil( productAdminResponse.data )
			)
				this.productData = productAdminResponse.data
			if ( this.productData ) {
				if ( this.productData.products && this.productData.products.length > 0 ) {
					this.totalProducts = this.productData.products.length
					this.lowStockItems = await this.GetLowStockItems()
					this.topProductSold = await this.productData.products.reduce(
						( prev, next ) => ( prev.sold > next.sold ? prev : next )
					).name
				}
				if ( this.productData.totalStock ) {
					this.totalStock = this.productData.totalStock
				}
				if ( this.productData.soldThisMonth ) {
					this.soldThisMonth = this.productData.soldThisMonth
				}
				if (
					this.productData.installerStockCount &&
          this.productData.installerStockCount.length > 0
				) {
					let installerStockCount = this.productData.installerStockCount.filter(
						( installer ) => installer.name !== 'Admin'
					)
					const max = await installerStockCount.reduce( ( acc, item ) =>
						acc.stockAmount > item.stockAmount ? acc : item
					)
					this.topPurchaser = max.name
				}
			}
		},
		GetLowStockItems() {
			this.productData.products.map( ( prod ) => {
				prod.inStock = this.productData.unassigned.filter(
					( uStock ) => uStock.productId === prod._id
				).length
			} )

			// console.log("modified stock:")
			// console.log(this.productData.products);
			return this.productData.products.filter( ( prod ) => prod.inStock < 2 )
				.length
		},
		async GetWorkService() {
			let serviceWorkResponse = await axios.get(
				process.env.VUE_APP_BASEURL + 'serviceWork/admin/dashData'
			)
			this.serviceData = serviceWorkResponse.data
			this.serviceWorksThisMonth = this.serviceData.totalThisMonth
			this.totalServices = this.serviceData.totalServices
			this.servicesToday = this.serviceData.servicesToday
			this.mostServiceWork = this.serviceData.mostServiceWork.name
			this.totalCustomers = this.serviceData.totalCustomers
		},
		InitializeChartData() {
			// debugger

			this.chartData = {
				labels: [],
				datasets: [
					{
						label: '',
						fill: false,
						backgroundColor: 'green',
						borderColor: 'green',
						data: [],
					},
				],
			}

			// this.chartData.datasets[0].label=

			// console.log(this.organizedSW)
			if ( this.organizedSW ) {
				this.chartData.datasets[0].label = new Date(
					Date.now()
				).toLocaleDateString( 'default', { month: 'long' } )
				// debugger
				this.organizedSW.forEach( ( sw ) => {
					// debugger
					// console.log("sw")
					// console.log(sw)
					// this.chartData.labels+="meow";
					this.chartData.labels.push( sw.createdAt )
					this.chartData.datasets[0].data.push( sw.count )
				} )
			}
			// console.log(this.chartData)
		},
		OrganizeWorkHistory() {
			this.serviceWorksThisMonth.forEach( ( sw ) => {
				// console.log(new Date(sw.createdAt).getDate())
				// debugger
				let workIndex = this.organizedSW.findIndex(
					( osw ) =>
						new Date( osw.createdAt ).getDate() ===
            new Date( sw.createdAt ).getDate()
				)
				if ( workIndex >= 0 ) {
					this.organizedSW[workIndex].count += 1
				} else {
					let work = {
						createdAt: new Date( sw.createdAt ).toDateString().substr( 0, 10 ),
						count: 1,
					}
					this.organizedSW.push( work )
				}
			} )
			// this.InitializeChartData()
			// console.log(this.organizedSW);
		},
	},
}
</script>

<style scoped>
h1 {
  font-size: 50px;
  font-weight: 500;
}
.cardlet {
  max-width: 350px;
  /* min-height: 200px; */
}
</style>
