import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import createRouter from './router'
import createStore from './store'
import vuetify from './plugins/vuetify'
import i18n from './i18n'
import axios from 'axios'
import VueQrCodeReader from 'vue-qrcode-reader'
import VueHtmlToPaper from 'vue-html-to-paper'
import * as pkg from '../package.json'

//LOGGING
//Sentry imports.
import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'
//logrocket imports
import LogRocket from 'logrocket'


const store = createStore( vuetify, i18n )
const router = createRouter( store )

const {version} = pkg

const htmlToPaperOptions = {
	styles: [
		'./styles/printable.css',
		'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
	],
}
let user = store.getters.getUser || null
let enableSentryLogging = process.env.NODE_ENV ==='production'

if( enableSentryLogging ){
	LogRocket.init( 'o8xmwg/nanogard-frontend-prod' )


	Sentry.init( {
		release: version,
		beforeSend( event ){
			try{
				event.extra['User'] = user !== null && user!= undefined ? user.name: 'NoUser'
				event.extra['Username'] = user !== null && user!== undefined? user.username: 'NoUserName'
				console.log( 'logging error to sentry/logrocket' )
				return event

			}
			catch( err ){
				return err
			}
      
     
		},
		Vue,
		dsn: process.env.VUE_APP_SENTRYURL,
		integrations: [
			new Integrations.BrowserTracing( {
				routingInstrumentation: Sentry.vueRouterInstrumentation( router ),
				tracingOrigins: ['localhost', 'app.thenanogard.com', /^\//],
			} ),
		],
		// Set tracesSampleRate to 1.0 to capture 100%
		// of transactions for performance monitoring.
		// We recommend adjusting this value in production
		tracesSampleRate: 1.0,
	} )
	LogRocket.getSessionURL( sessionURL => {
		Sentry.configureScope( scope => {
			scope.setExtra( 'sessionURL', sessionURL )
		} )
	} )
  
	// console.log(user)
	if( user!==null ){
		LogRocket.identify( user._id, {
			name: user.name,
			username: user.username,
  
			role: user.role
  
		} )
  
	}
	else{
		LogRocket.identify( -1, {
			name: '-=Anonymous User=-',
			username: '-=Anonymous User=-',
  
			role: '-=Anonymous User=-'
  
		} )
	}
}
else{
	console.log( '-=Not logging to sentry=-' )
}

Vue.config.productionTip = false
Vue.prototype.$http = axios
Vue.use( vuetify )
Vue.use( router )
Vue.use( VueQrCodeReader )
Vue.use( VueHtmlToPaper, htmlToPaperOptions )
export const bus = new Vue()

new Vue( {
	router,
	i18n,
	vuetify,
	store,
	axios,
	render: ( h ) => h( App ),
} ).$mount( '#app' )
